import { mobileBreakPoint } from 'config/spacing';
import React from 'react';
import DeskproLogo from 'static/DeskproLogo.svg';
import GeneralAssemblyLogo from 'static/GeneralAssemblyLogo.svg';
import LyvlyLogo from 'static/LyvlyLogo.svg';
import styled from 'styled-components';

import { ExperienceItem } from './ExperienceItem';

const Container = styled.div`
  width: 100%;
  height: ${(props) => `${props.stories * 200}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;

  @media (min-width: ${mobileBreakPoint}px) {
    width: 100%;
    align-items: start;
  }
`;

const experiences = [
  {
    src: DeskproLogo,
    alt: 'Deskpro',
    height: 70,
    width: 70,
    backgroundColor: 'white',
    url: 'deskpro'
  },
  {
    src: LyvlyLogo,
    alt: 'Lyvly logo',
    height: 70,
    width: 70,
    backgroundColor: 'white',
    url: 'lyvly'
  },
  {
    src: GeneralAssemblyLogo,
    alt: 'General Assembly logo',
    height: 160,
    width: 160,
    backgroundColor: 'black',
    url: 'generalAssembly'
  }
];

export const Experiences = () => (
  <Container stories={experiences.length}>
    {experiences.map((exp) => (
      <ExperienceItem
        src={exp.src}
        alt={exp.alt}
        height={exp.height}
        width={exp.width}
        backgroundColor={exp.backgroundColor}
        url={exp.url}
      />
    ))}
  </Container>
);
