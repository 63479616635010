export const nameHeadingText = {
  freddie: 'FREDDIE',
  hoy: 'HOY'
};

export const navBarText = {
  experience: 'Exp',
  about: 'About',
  game: 'Game'
};
