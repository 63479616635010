import { BackArrow } from 'components/common/BackArrow';
import { Container } from 'components/common/Container';
import { colors } from 'config/colors';
import { BlueText, HeadingOne, HeadingTwo, Paragraph, RedText } from 'config/fontStyles';
import { mobileBreakPoint, tabletBreakPoint } from 'config/spacing';
import React from 'react';
import deskproTeam from 'static/deskproTeam.webp';
import styled from 'styled-components';

const HeadingContainer = styled(HeadingOne)`
  text-align: center;
  margin: 20px auto;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 20px 0;
`;

const InfoContainer = styled.div`
  @media (min-width: ${tabletBreakPoint}px) {
    margin: 10px 0;
  }
`;

const RoleContainer = styled.div`
  @media (min-width: ${mobileBreakPoint}px) {
    width: 100%;
  }
  @media (min-width: ${tabletBreakPoint}px) {
    margin: 20px 0;
  }
`;

const TeachSheetContainer = styled.div`
  text-align: left;
  width: 100%;

  @media (min-width: ${mobileBreakPoint}px) {
    width: 100%;
  }

  @media (min-width: ${tabletBreakPoint}px) {
    margin: 20px 0;
  }
`;

const TechList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TechItem = styled(Paragraph)`
  margin: 0;
  font-size: 18px;
  width: 48%;
  transition: color 0.4s ease;
  :hover {
    color: ${colors.redText};
  }
  @media (min-width: ${mobileBreakPoint}px) {
    width: 32%;
  }
`;

export const DeskproExp = () => (
  <Container>
    <BackArrow toPage="/experience" />
    <HeadingContainer>
      Working at
      <RedText> Deskpro</RedText>
      <BlueText>.</BlueText>
    </HeadingContainer>
    <InfoContainer style={{ flexDirection: 'column' }}>
      <Paragraph>
        <RedText>Role: </RedText>
        Frontend Product Engineer.
      </Paragraph>
      <Paragraph>
        <RedText>Dates: </RedText>
        June 2020 - March 2023
      </Paragraph>
      <Paragraph>
        Deskpro creates a helpdesk & customer support software SaaS product. Their primary focus is
        on creating comprehensive, all-in-one platforms that assist businesses in efficiently
        managing and streamlining their customer support operations.
      </Paragraph>
      <Paragraph>
        Deskpro&apos; software typically includes ticketing systems, knowledge bases, live chat
        support, and other features designed to enhance customer service experiences. By
        centralizing customer communications and support requests, Deskpro aims to help
        organizations improve their response times, resolve issues more effectively, and ultimately
        enhance customer satisfaction.
      </Paragraph>
    </InfoContainer>
    <StyledImage src={deskproTeam} alt="Deskpro Team" />
    <RoleContainer>
      <HeadingTwo>
        <RedText>My Role</RedText>
      </HeadingTwo>
      <Paragraph>
        <RedText>Frontend Product Engineer </RedText>
        <span>
          {' '}
          - I joined Deskpro at the start of there journey to completely rebuilding the FE of their
          application in React, TS and Apollo. We Built the product, the admin & agent interfaces
          and UI library from scratch over 2 years. At which point customers where migrated over and
          we started building new features.
        </span>
      </Paragraph>
      <HeadingTwo>
        <RedText>My Achievements</RedText>
      </HeadingTwo>
      <Paragraph>
        <ul>
          <li>
            <RedText>Design & Build a ticket Kanban Board</RedText>
            <span>. A new high traffic & complex feature for using tickets.</span>
          </li>
          <li>
            <RedText>Redesigned & built the core of the product. </RedText>
            All of the ticket views, tables, lists, dual.
          </li>
          <li>
            <RedText>React 18 upgrade.</RedText>
            <span> Including the entire FE of the app & the UI component library.</span>
          </li>
          <li>
            <RedText>Ownership of packages.</RedText>
            <span> Regular updates/removing other node packages for product & library.</span>
          </li>
          <li>
            <RedText>Set up & run Smunches (Smart lunches). </RedText>
            <span>
              A time to encourage all engineers to share/present technologies or topics of interest
              to further teams learning and highlight new industry tech.
            </span>
          </li>
          <li>
            <RedText>Set up an error logging pipe line with Sentry.</RedText>
            <span>A process to triage errors coming from customers.</span>
          </li>
          <li>
            <RedText>Assisted in the technical interview stage.</RedText>
            Interviewed contractors with another engineer.
          </li>
        </ul>
      </Paragraph>
    </RoleContainer>
    <TeachSheetContainer>
      <HeadingTwo>
        <RedText>Tech Sheet</RedText>
      </HeadingTwo>
      <TechList>
        <TechItem>TypeScript</TechItem>
        <TechItem>React</TechItem>
        <TechItem>ApolloGQL</TechItem>
        <TechItem>Jest</TechItem>
        <TechItem>React Testing Library</TechItem>
        <TechItem>Cypress</TechItem>
        <TechItem>Git</TechItem>
        <TechItem>Zod</TechItem>
        <TechItem>Zustand</TechItem>
        <TechItem>Styled Components</TechItem>
        <TechItem>Webpack -&gt; ES Build</TechItem>
      </TechList>
    </TeachSheetContainer>
  </Container>
);
